<!-- @format -->

<template>
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col cols="12" md="9" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
          <label>每页条数</label>
          <v-select v-model="perPage" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block ml-50 mr-1" />
          <b-button variant="primary" :to="{ name: 'my_order_create' }"> 添加 </b-button>
        </b-col>

        <!-- Search -->
        <b-col cols="12" md="3">
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="请输入订单编号" />
            <v-select @search="fetchVipData" style="min-width: 220px" v-model="user_id" :tabindex="1" label="real_name" :reduce="item => item.id" placeholder="请选择会员" :options="vipList" />
          </div>
        </b-col>
      </b-row>
    </div>

    <b-table ref="orderListTable" :items="fetchInvoices" responsive :fields="tableColumns" primary-key="id" show-empty empty-text="No matching records found" class="position-relative">
      <template #head(invoiceStatus)>
        <feather-icon icon="TrendingUpIcon" class="mx-auto" />
      </template>

      <!-- Column: Id -->
      <template #cell(id)="data">
        <b-link class="font-weight-bold"> #{{ data.value }} </b-link>
      </template>

      <!-- Column: Invoice Status -->
      <template #cell(status)="data">
        <b-badge :variant="data.value | filterStatusColor" class="mx-auto">{{ data.value | filterStatusTxt }}</b-badge>
      </template>

      <!-- Column: Issued Date -->
      <template #cell(issuedDate)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="f-flex">
          <!--      编辑    -->
          <b-button size="sm" variant="primary" :to="{ name: 'my_order_edit' }" @click.stop.prevent="$store.commit(`user-order/setOrderDetail`, data.item)">
            编辑
            <feather-icon icon="EditIcon" />
          </b-button>
          <!--      删除    -->
          <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
            <template v-slot:button-content>
              <b-button size="sm" variant="danger">
                删除
                <feather-icon icon="TrashIcon" />
              </b-button>
            </template>
            <b-dropdown-item>
              <feather-icon icon="TrashIcon" class="mr-50" />
              <span @click="deleteInvoice(data.item.id)">确认删除</span>
            </b-dropdown-item>
          </b-dropdown>
          <!--          审核-->
          <b-button size="sm" variant="success" @click.prevent.stop=";[$bvModal.show('audit-modal'), (checkForm.id = data.item.id)]">
            审核
            <feather-icon icon="CheckIcon" />
          </b-button>
        </div>
      </template>
    </b-table>

    <b-modal id="audit-modal" cancel-variant="outline-secondary" ok-title="确认" cancel-title="取消" centered title="订单审核">
      <validation-observer ref="auditForm">
        <b-form>
          <b-form-group>
            <label>订单TP值:</label>
            <validation-provider #default="{ errors }" name="订单TP" rules="required">
              <b-form-input v-model="checkForm.order_tp" type="email" placeholder="请输入订单TP" :state="errors.length > 0 ? false : null" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label>订单状态</label>
            <validation-provider #default="{ errors }" name="审核状态" rules="required">
              <v-select
                v-model="checkForm.status"
                :reduce="item => item.value"
                :placeholder="'请选择状态'"
                :state="errors.length > 0 ? false : null"
                :class="[errors.length > 0 ? 'style-chooser' : 'style-chooser-none']"
                :options="[
                  { label: '已提交', value: 1 },
                  { label: '已处理', value: 2 },
                  { label: '已生效', value: 3 },
                ]"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
      <!--   footer   -->
      <template #modal-footer>
        <b-button variant="secondary" @click.prevent="$bvModal.hide('audit-modal')">取消</b-button>
        <b-button variant="primary" @click.prevent="auditInvoice">确认</b-button>
      </template>
    </b-modal>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination v-model="currentPage" :total-rows="totalInvoices" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BButton, BTable, BLink, BBadge, BDropdown, BDropdownItem, BPagination } from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { reactive, getCurrentInstance, onMounted } from '@vue/composition-api'
import { required } from '@core/utils/validations/validations'
import axiosIns from '@/libs/axios'
import useInvoicesList from './useOrderList'

import orderModule from './OrderManagerList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  filters: {
    filterStatusTxt(value) {
      switch (value) {
        case 1:
          return '已提交'
        case 2:
          return '已处理'
        case 3:
          return '已生效'
        default:
          return '未知'
      }
    },
    filterStatusColor(value) {
      switch (value) {
        case 1:
          return 'primary'
        case 2:
          return 'success'
        case 3:
          return 'success'
        default:
          return 'danger'
      }
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'user-order'
    const { proxy } = getCurrentInstance()
    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, orderModule)

    const {
      user_id,
      auditInvoice: auditInvoiceStore,
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      orderListTable,
      deleteInvoice,
      refetchData,
    } = useInvoicesList()

    const checkForm = reactive({
      order_tp: '',
      status: '',
      id: '',
    })

    let vipList = reactive([])
    const fetchVipData = (search, loading) => {
      if (search.length) {
        loading(true)
        axiosIns.get('/user/list', { params: { name: search } }).then(response => {
          if (response.code === 0) {
            vipList = response.data.list
            loading(false)
          }
        })
      }
    }
    onMounted(() => {
      axiosIns.get('/user/list').then(response => {
        if (response.code === 0) {
          vipList.splice(0, vipList.length, ...response.data.list)
        }
      })
    })

    const auditInvoice = () => {
      proxy.$refs.auditForm.validate().then(isOk => {
        if (isOk) {
          auditInvoiceStore(checkForm)
        } else {
          console.log('error')
        }
      })
    }

    return {
      fetchInvoices,
      tableColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      orderListTable,

      refetchData,

      deleteInvoice,
      auditInvoice,
      fetchVipData,
      checkForm,
      required,
      INVOICE_APP_STORE_MODULE_NAME,
      user_id,
      vipList,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
