/** @format */

import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useInvoicesList() {
  // Use toast
  const toast = useToast()

  const orderListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '#' },
    {
      key: 'product.title',
      label: '产品名称',
    },
    {
      key: 'member.real_name',
      label: '会员名称',
    },
    {
      key: 'except_rate',
      label: '被除率',
      formatter: value => (!value ? '-- 无 --' : `${value}%`),
    },
    {
      key: 'number',
      label: '订单编号',
    },
    {
      key: 'deal_time',
      label: '交易时间',
    },
    {
      key: 'order_tp',
      label: '订单TP',
    },
    {
      key: 'user_income',
      label: '佣金收入',
    },
    // {
    //   key: 'is_renew',
    //   label: '是否续费',
    //   formatter: value => (value === 1 ? '是' : '否'),
    // },
    {
      key: 'status',
      label: '订单状态',
    },
    {
      key: 'create_user',
      label: '创建人',
    },
    {
      key: 'submit_at',
      label: '创建时间',
    },
    {
      key: 'actions',
      label: '操作',
    },
  ]
  const perPage = ref(100) // pageSize
  const totalInvoices = ref(0) // total
  const currentPage = ref(1) // now Page
  const perPageOptions = [10, 25, 50, 100] // pageSize Array
  const searchQuery = ref('') // searchQuery

  const user_id = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = orderListTable.value ? orderListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
    }
  })

  const refetchData = () => {
    orderListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, user_id], () => {
    console.log(user_id)
    refetchData()
  })

  const fetchInvoices = (ctx, callback) => {
    store
      .dispatch('user-order/fetchListData', {
        perPage: perPage.value,
        page: currentPage.value,
        number: searchQuery.value,
        user_id: user_id.value,
      })
      .then(response => {
        const { list, total } = response.data
        callback(list)
        totalInvoices.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching invoices' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // 删除
  const deleteInvoice = val => {
    store
      .dispatch('user-order/orderDelete', val)
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Successfully deleted invoice',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        refetchData()
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: '删除错误!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  // 审核
  const auditInvoice = val => {
    store
      .dispatch('user-order/orderAudit', val)
      .then(response => {
        if (response.data.status === 0) {
          toast({
            component: ToastificationContent,
            props: {
              title: response.msg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          refetchData()
        } else {
          toast({
            component: ToastificationContent,
            props: {
              title: response.msg,
              icon: 'CheckCircleIcon',
              variant: 'danger',
            },
          })
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: '审核错误!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  // 订单详情

  return {
    fetchInvoices,
    tableColumns,
    perPage,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    searchQuery,
    orderListTable,
    deleteInvoice,
    auditInvoice,
    refetchData,
    user_id,
  }
}
